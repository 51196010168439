import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as timezone from 'dayjs/plugin/timezone';
import * as minMax from 'dayjs/plugin/minMax';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import * as itLocale from 'dayjs/locale/it';

dayjs.locale(itLocale.name);
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(minMax);
dayjs.extend(localizedFormat);
export const itTz = 'Europe/Rome';
export const utcTz = 'GMT';