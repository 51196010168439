import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, delay, distinctUntilChanged, filter, map, shareReplay, take, tap } from 'rxjs/operators';
import { Account } from '../models';
import { LoginAsUserDto, LoginUserDto } from '../models/login-user.dto';
import { ApiService } from './api.service';
import { JwtService } from './jwt.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private currentUserSubject = new BehaviorSubject<Account>(null);
  public currentUser = this.currentUserSubject.asObservable().pipe(
    // filter((account: Account) => !!account),
    distinctUntilChanged(),
    shareReplay(1),
  );

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated$ = this.isAuthenticatedSubject.asObservable();
  private renderer: Renderer2;

  constructor(
    private apiService: ApiService,
    private jwtService: JwtService,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  populate() {
    if (this.jwtService.getToken()) {
      this.apiService
        .get('/user/me')
        .pipe(
          take(1),
          tap((account: Account) => {
            this.setAuth(account);
          }),
          catchError((err) => {
            this.purgeAuth()
            return throwError(() => err);
          })
        ).subscribe();
    } else {
      this.purgeAuth();
    }
  }

  setAuth(user: Account) {
    this.jwtService.saveToken(user.token);
    this.currentUserSubject.next(user);
    this.isAuthenticatedSubject.next(true);
    
    /* rimuove lo splashscreen all'avvio in index.html */
    let loader = this.renderer.selectRootElement('#splash-screen');
    this.renderer.setStyle(loader, 'display', 'none');
  }

  purgeAuth() {
    this.jwtService.destroyToken();
    this.currentUserSubject.next(null);
    this.isAuthenticatedSubject.next(false);
  }

  attemptAuth(credentials: LoginUserDto): Observable<Account> {
    return this.apiService.post('/user/v2/login', credentials).pipe(
      take(1),
      tap((account: Account) => this.setAuth(account))
    );
  }

  attemptAuthAdminAsUser(params: LoginAsUserDto): Observable<Account> {
    return this.apiService.post('/user/login-as-user', params).pipe(
      take(1),
      map((account: Account) => {
        this.setAuth(account);
        return account;
      })
    );
  }

  ssoLogin(cse_srtoken: string, code: string): Observable<Account> {
    const param = {
      cse_srtoken: cse_srtoken,
      code: code
    }
    return this.apiService.post('/user/sso/login', param).pipe(
      take(1),
      map((account: Account) => {
        this.jwtService.saveAccessToken(account.access_token)
        this.setAuth(account);
        return account;
      })
    )
  }


  getCurrentUser(): Account {
    return this.currentUserSubject.value;
  }
}
