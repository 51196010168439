import { Injectable } from '@angular/core';
import { FilterMatchMode, PrimeNGConfig } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class InternationalizationService {
  constructor(private readonly primeNgConfig: PrimeNGConfig) {}

  setLocalization(lang: 'en' | 'it') {
    const translation = this.getLocalization(lang);
    this.primeNgConfig.setTranslation(translation);
    this.primeNgConfig.filterMatchModeOptions = {
      text: [FilterMatchMode.CONTAINS, FilterMatchMode.NOT_CONTAINS, FilterMatchMode.EQUALS],
      numeric: [
        FilterMatchMode.EQUALS,
        FilterMatchMode.NOT_EQUALS,
        FilterMatchMode.LESS_THAN,
        FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
        FilterMatchMode.GREATER_THAN,
        FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
      ],
      date: [
        FilterMatchMode.DATE_IS,
        FilterMatchMode.DATE_IS_NOT,
        FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
        FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
      ],
    };
  }

  private getLocalization(lang: 'en' | 'it'): any {
    const localizations = {
      en: {
        startsWith: 'Starts with',
        contains: 'Contains',
        notContains: 'Not contains',
        endsWith: 'Ends with',
        equals: 'Equals',
        notEquals: 'Not equals',
        noFilter: 'No Filter',
        lt: 'Less than',
        lte: 'Less than or equal to',
        gt: 'Greater than',
        gte: 'Greater than or equal to',
        is: 'Is',
        isNot: 'Is not',
        before: 'Before',
        after: 'After',
        dateIs: 'Date is',
        dateIsNot: 'Date is not',
        dateBefore: 'Date is before',
        dateAfter: 'Date is after',
        clear: 'Clear',
        apply: 'Apply',
        matchAll: 'Match All',
        matchAny: 'Match Any',
        addRule: 'Add Rule',
        removeRule: 'Remove Rule',
        accept: 'Yes',
        reject: 'No',
        choose: 'Choose',
        upload: 'Upload',
        cancel: 'Cancel',
        dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        monthNames: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ],
        monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        dateFormat: 'mm/dd/yy',
        firstDayOfWeek: 0,
        today: 'Today',
        weekHeader: 'Wk',
        weak: 'Weak',
        medium: 'Medium',
        strong: 'Strong',
        passwordPrompt: 'Enter a password',
        emptyMessage: 'No results found',
        emptyFilterMessage: 'No results found',
      },
      it: {
        startsWith: 'Inizia con',
        contains: 'Contiene',
        notContains: 'Non contiene',
        endsWith: 'Finisce con',
        equals: 'Uguale',
        notEquals: 'Non è uguale',
        noFilter: 'Senza filtro',
        lt: 'Minore di',
        lte: 'Minore o uguale di',
        gt: 'Maggiore di',
        gte: 'Maggiore o uguale di',
        is: 'è',
        isNot: 'Non è',
        before: 'Prima',
        after: 'Dopo',
        dateIs: 'In data',
        dateIsNot: 'In data diversa da',
        dateBefore: 'Prima del',
        dateAfter: 'Dopo il',
        clear: 'Pulisci',
        apply: 'Applica',
        matchAll: 'Tutti',
        matchAny: 'Almeno uno',
        addRule: 'Aggiungi regola',
        removeRule: 'Rimuovi regola',
        accept: 'Si',
        reject: 'No',
        choose: 'Scegli',
        upload: 'Upload',
        cancel: 'Annulla',
        dayNames: ['Domenica', 'Lunedi', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
        dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
        dayNamesMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
        monthNames: [
          'Gennaio',
          'Febbraio',
          'Marzo',
          'Aprile',
          'Maggio',
          'Giugno',
          'Luglio',
          'Agosto',
          'Settembre',
          'Ottobre',
          'Novembre',
          'Dicembre',
        ],
        monthNamesShort: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
        dateFormat: 'dd/mm/yy',
        firstDayOfWeek: 1,
        today: 'Oggi',
        weekHeader: 'Sett.',
        weak: 'Debole',
        medium: 'Media',
        strong: 'Robusta',
        passwordPrompt: 'Inserisci una password',
        emptyMessage: 'Nessun risultato trovato',
        emptyFilterMessage: 'Nessun risultato trovato',
      },
    };

    const localization = localizations[lang];
    return localization;
  }
}
