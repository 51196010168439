import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AngularLoggerService {

  constructor(private apiService: ApiService) { }

  logError(error: any) {
    this.apiService.post('/logs/angular/errors', {
      message: error.stack ?? JSON.stringify(error)
    }).subscribe((response) => {
      console.error(error);
    });
  }
}
