import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Params,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { AuthService } from '../services';

@Injectable({ providedIn: 'root' })
export class IsLoggedGuard {
  user$: Observable<boolean>;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  checkIfUserIsAuthenticated(params?: Params, redirect?: string) {
    this.user$ = this.authService.isAuthenticated$;

    return this.user$.pipe(
      distinctUntilChanged(),
      tap((isAuth: boolean) => {
        if (!isAuth) {
          if (params && params.code && params.email) {
            const queryParams: Params = { code: params.code, email: params.email, redirect };
            this.router.navigate(['/login'], { queryParams });
          } else {
            if (redirect) {
              const queryParams: Params = { redirect };
              this.router.navigate(['/login'], { ...(queryParams.redirect !== '/' ? { queryParams } : {}) });
            } else {
              this.router.navigate(['/login']);
            }

          }
        }
      }),
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const url = state.url;
    const relativePath = url.split('?');
    return this.checkIfUserIsAuthenticated(route.queryParams, relativePath[0]);
  }

}
