import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IsLoggedGuard } from '@core/guards/is-logged.guard';


const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./modules/main/app.main.module')
            .then(mod => mod.AppMainModule),
        canActivate: [IsLoggedGuard],
    },
    {
        path: 'login',
        loadChildren: () => import('./modules/login/app.login.module')
            .then(mod => mod.AppLoginModule)
    },
    {
        path: 'recupera-password',
        loadChildren: () => import('./modules/recovery-password/recovery-password.module')
            .then(mod => mod.RecoveryPasswordModule)
    },
    {
        path: 'primoaccesso',
        loadChildren: () => import('./modules/recovery-password/recovery-password.module')
            .then(mod => mod.RecoveryPasswordModule)
    },
    {
        path: 'manutenzione',
        loadChildren: () => import('./modules/maintenance/maintenance.module')
            .then(mod => mod.MaintenanceModule)
    },
    {
        path: 'network-error',
        loadChildren: () => import('./modules/network-error/network-error.module')
            .then(mod => mod.NetworkErrorModule)
    },
    {
        path: 'sso/:code',
        loadChildren: () => import('./modules/sso/sso.module')
            .then(mod => mod.SsoModule)
    },
    {
        path: 'pagina-non-trovata',
        loadChildren: () => import('./modules/page-not-found/app.notfound.module')
            .then(mod => mod.AppNotFoundModule)
    },
    {
        path: '**',
        loadChildren: () => import('./modules/page-not-found/app.notfound.module')
            .then(mod => mod.AppNotFoundModule)
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
