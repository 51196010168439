import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtService } from '@core/services';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
    constructor(
        private jwtService: JwtService,
        private router: Router,
    ) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        const headersConfig = {
            Platform: 'web'
        };

        const token = this.jwtService.getToken();
        if (token) {
            headersConfig['Authorization'] = `Bearer ${token}`;
        }

        const request = req.clone({
            setHeaders: headersConfig,
        });

        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.status === HttpStatusCode.Unauthorized && error?.error?.code === 'LOGIN_ERROR_WRONG_CREDENTIALS') {
                        this.router.navigate(['/login'], { queryParams: { session: null } });
                    } else if (error.status === HttpStatusCode.Unauthorized) {
                        this.router.navigate(['/login'], { queryParams: { session: 'expired' } });
                    }
                    return throwError(() => error);
                })
            );
    }
}
