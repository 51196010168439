import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class JwtService {
  getToken(): String {
    return window.localStorage.getItem('CampaJwtToken');
  }

  saveToken(token: string) {
    window.localStorage.setItem('CampaJwtToken', token);
  }

  saveAccessToken(token: string) {
    window.localStorage.setItem('access_token', token);
  }

  getAccessToken(): string {
    return window.localStorage.getItem('access_token');
  }

  destroyToken() {
    window.localStorage.removeItem('CampaJwtToken');
    window.localStorage.removeItem('access_token');
  }
}
