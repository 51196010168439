import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { AuthService } from './core/services';
import './core/common/dayjs';
import { InternationalizationService } from './core/i18n';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription, distinctUntilChanged, tap } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit{

    layoutMode = 'static';

    lightMenu = true;

    topbarColor = 'layout-topbar-campa';

    inlineUser = false;

    isRTL = false;

    inputStyle = 'outlined';

    ripple = true;

    routerEvent$: Subscription;

    constructor(
        private primengConfig: PrimeNGConfig, 
        private authService: AuthService, 
        private i18nService: InternationalizationService,
        private router: Router
        ) {
        this.i18nService.setLocalization('it');

        this.routerEvent$ = this.router.events.pipe(
            tap(e => {
                if (e instanceof NavigationStart) {
                    const url = e.url;
                    if (url.split('/')[1] !== 'sso' && !url.split('/')[1].startsWith('login')) {
                        this.authService.populate();
                        this.routerEvent$.unsubscribe();
                    }
                }
            })
        ).subscribe();
    }

    ngOnInit() {
        this.primengConfig.ripple = true;
    }
}
