import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularErrorHandler } from '@core/services/angularerrorhandler';
import { AngularLoggerService } from '@core/services/angularlogger.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt);
@NgModule({
    imports: [
        CoreModule,
        BrowserModule,
        BrowserAnimationsModule,
        ButtonModule,
        InputTextModule,
        AppRoutingModule,
        FontAwesomeModule
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: LOCALE_ID, useValue: 'it-IT' },
        AngularLoggerService, {
            provide: ErrorHandler,
            useClass: AngularErrorHandler
        },
    ],
    bootstrap: [AppComponent]

})
export class AppModule {
}
