import { HttpResponseBase } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { AngularLoggerService } from './angularlogger.service';

@Injectable()
export class AngularErrorHandler extends ErrorHandler {
  constructor(private angularLoggerService: AngularLoggerService) {
    super();
  }

  handleError(error: HttpResponseBase) {
    const url = error.url ? new URL(error.url) : null;
    if (!url || (url && !url.pathname.includes('/user/v2/login'))) {
      this.angularLoggerService.logError(error);
    }
  }
}
