import {
  HttpClient,
  HttpEventType,
  HttpHeaders
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  private formatErrors(error: any) {
    return throwError(() => error);
  }

  get<T = any>(path: string, params?: any): Observable<T> {
    return this.http
      .get<T>(`${environment.API_URL}${path}`, { params })
      .pipe(
        catchError(e => {
          return this.formatErrors(e);
        }),
        shareReplay(1)
      );
  }

  put<T = any>(path: string, body: object = {}): Observable<T> {
    return this.http
      .put<T>(`${environment.API_URL}${path}`, body)
      .pipe(
        catchError(e => {
          return this.formatErrors(e);
        }),
      );
  }

  patch<T = any>(path: string, body: object = {}): Observable<T> {
    return this.http
      .patch<T>(`${environment.API_URL}${path}`, body)
      .pipe(
        catchError(e => {
          return this.formatErrors(e);
        }),
      );
  }

  post<T = any>(path: string, body: object = {}): Observable<T> {
    return this.http
      .post<T>(`${environment.API_URL}${path}`, body)
      .pipe(
        catchError(e => {
          return this.formatErrors(e);
        }),
        shareReplay(1)
      );
  }

  delete<T = any>(path: string): Observable<T> {
    return this.http
      .delete<T>(`${environment.API_URL}${path}`)
      .pipe(
        catchError(e => {
          return this.formatErrors(e);
        }),
      );
  }

  uploadFile<T = any>(path: string, files: File[], params?: object): Observable<T> {
    const formData: any = new FormData();
    files.forEach(file => {
      const name = file['fieldName'] ?? 'upload';
      formData.append(name, file, file['name']);
    });

    if (params) {
      const paramrArray = Object.keys(params)
        .map(key => ({ name: key, value: params[key] }));
      paramrArray.forEach(param => {
        formData.append(param.name, param.value);
      });
    }

    return this.http
      .post<T>(`${environment.API_URL}${path}`, formData, {
        headers: new HttpHeaders({
          'X-Requested-With': 'XMLHttpRequest',
          Accept: 'application/json',
        }),
        reportProgress: true,
        observe: 'events'
      })
      .pipe(map((event) => {
        switch (event.type) {

          case HttpEventType.UploadProgress:
            const progress = Math.round(100 * event.loaded / event.total);
            return { status: 'progress', message: progress };

          case HttpEventType.Response:
            const res: any = { body: event.body, status: event.status };
            return res;
          default:
            return `Unhandled event: ${event.type}`;
        }
      }));
  }

  getPDF<T = any>(path: string, body: object = {}): Observable<T> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json'
    });
    return this.http
      .post<T>(`${environment.API_URL}${path}`, body, { headers: headers })
      .pipe(catchError(this.formatErrors));
  }
}
