import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AutoComplete } from 'primeng/autocomplete';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

AutoComplete.prototype.onInputBlur = function (this: AutoComplete, event: KeyboardEvent) {
  try {
    if (this.highlightOption) {
      this.selectItem(this.highlightOption, false);
      this.cd.detectChanges();
    }
  } catch (e) {
  }

  this.focus = false;
  this.onModelTouched();
  this.onBlur.emit(event);
};